import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/pieces-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1982 slasher movie, Pieces"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 03</h1>
                    <h2>Pieces</h2>
                    <h3>March 20, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <h1>Transcript</h1>
                        <p><Link to="/episodes/pieces">Back to the episode details</Link></p>
                        <div>
                            <p>Bryan White (00:02.455)
                            You&#39;re listening to the Brothers Grim podcast presented by the Cinema Suicide Film Society on Substack. If you want to keep up with us between episodes, you can find us on Twitter at GrimbrosPod. And if you like what you hear, you can subscribe to us wherever you get your podcast. Also you&#39;d be doing us a favor by leaving us a five-star review on Apple Podcasts. I just want to get all that out of the way right at the top of the show. I&#39;m Brian White, editor in grief for Cinema Suicide. I&#39;m joined by my co-host and actual brother Dave White. Dave, how you doing?</p>
                            <p>Dave (00:30.091)
                            Well, pretty good, pretty good.</p>
                            <p>Bryan White (00:31.875)
                            Alright, not bad, not bad. So we practically grew up in neighborhood video stores, steady diet of utter garbage that those shops provided us with, continues unabated to this day. There&#39;s no one else I enjoy chopping it up with more about trashy movies than Dave.</p>
                            <p>Bryan White (00:48.195)
                            Ooh, this one. I have been looking forward to it, looking forward to it. I had not seen this before. I&#39;d only, I tried to watch it several times over the years. I had like a VHS bootleg of it, like a million years ago when I was trading tapes. And like I never managed to make it very far cause it is a very special kind of movie. And I was, I just guessed out, I was never really in the mood for it at that time.</p>
                            <p>Dave (01:18.511)
                            It&#39;s an experience.</p>
                            <p>Bryan White (01:19.619)
                            It is it&#39;s a real it&#39;s a real experience. So let&#39;s give you let&#39;s give you a taste of what&#39;s coming up here</p>
                            <p>Dave (02:05.271)
                            real visual experience.</p>
                            <p>Bryan White (02:07.141)
                            Yeah, I guess you gotta see it.</p>
                            <p>Bryan White (02:13.155)
                            Jesus Christ, is this just the beginning of the movie?</p>
                            <p>Bryan White (02:22.215)
                            uh yeah it is, fuck it, okay never mind because that&#39;s not the trailer that&#39;s just literally the beginning of the movie, whatever</p>
                            <p>Dave (02:28.85)
                            Yeah, based on that, I would not watch this movie.</p>
                            <p>Bryan White (02:31.056)
                            Now I&#39;m gonna cut that whole part out.</p>
                            <p>Bryan White (02:36.695)
                            Okay, so the movie that we&#39;re watching is 1982&#39;s Pieces as we go back back to the well in 1982 which you know I&#39;m telling you what as I go over there more movies released that year as I did the last one and the one before that.</p>
                            <p>Dave (02:53.85)
                            Yeah, it&#39;s when they figured it out, like they knew what they were doing.</p>
                            <p>Bryan White (03:05.515)
                            still took him a couple of years for Friday the 13th to really kind of like Be the the sort of like proving grounds for it where it&#39;s like holy shit I guess we could make a lot of money on this so</p>
                            <p>Dave (03:15.57)
                            Yeah, because every film producer was like, wait, so it&#39;s like just a guy who kills ladies? Like that&#39;s that&#39;s the whole thing.</p>
                            <p>Bryan White (03:22.183)
                            And we can make this for less than a million dollars.</p>
                            <p>Dave (03:24.65)
                            And you say we can make 4,000 of them? Okay. Oh, man. People are dumb.</p>
                            <p>Bryan White (03:28.575)
                            And they&#39;ll never get tired of him. That&#39;s crazy. All right. Yeah. So 1982, other movies released that year. The House on Sorority Row, Parasite, which was Charles Band&#39;s first 3D movie. Then Q, the Winged Serpent, The Entity, which is a dark one.</p>
                            <p>Dave (03:39.35)
                            Yes.</p>
                            <p>Dave (03:48.57)
                            The Winged Serpent?</p>
                            <p>Dave (03:55.694)
                            Yeah, put a pin in that one. We&#39;ll come back to that.</p>
                            <p>Bryan White (03:57.275)
                            Yeah, come back to that. We&#39;re going to do the entity eventually. Cat people, which is a wild ride. And then, oh, and swapping.</p>
                            <p>Dave (04:08.831)
                            which is a ridiculous ride.</p>
                            <p>Bryan White (04:10.495)
                            ridiculous. It&#39;s awesome. So let&#39;s see, some cast and crew. So this was directed by Juan Simón. He also did Slugs and Pod People, which is an all-time best episode of Mystery Science Theater 3000. And I&#39;m definitely gonna, I have not seen it. I know that it&#39;s like wicked</p>
                            <p>Dave (04:24.995)
                            Slug scared the crap out of me when I was a kid.</p>
                            <p>Dave (04:31.75)
                            Yeah, it&#39;s about killer slugs that come into the country on tainted lettuce.</p>
                            <p>Bryan White (04:36.355)
                            Yeah. Yeah. So this movie stars Christopher George, who just a year before was over in Italy, doing City of the Living Dead. He&#39;s also in Mortuary, which we mentioned in the last episode because of the Bill Paxton connection.</p>
                            <p>Dave (04:40.57)
                            Yes!</p>
                            <p>Dave (04:52.75)
                            Yeah, Christopher George and Linda Day George are like the king and queen of television films.</p>
                            <p>Bryan White (04:57.795)
                            Yeah, like I looked at I looked up her Filmography and she basically just did TV and she did a shitload of TV</p>
                            <p>Dave (05:05.59)
                            Yeah, they were just two movies together. And then when he, because he died like right after this movie came out, maybe a year later, but as soon as he died, she just stopped acting.</p>
                            <p>Bryan White (05:10.897)
                            Yeah.</p>
                            <p>Bryan White (05:14.575)
                            Did she? Because I thought she kept going. But anyways, I mean, hey, that makes sense. But yeah, she did a ton.</p>
                            <p>Dave (05:15.371)
                            Yeah.</p>
                            <p>Dave (05:18.91)
                            No, she was just, it was fun for her and then when he wasn&#39;t there anymore, it wasn&#39;t fun.</p>
                            <p>Bryan White (05:22.995)
                            I would imagine that like, because I have a theory about this movie because all of the parts that have him and her in it are kind of like all of John Saxon&#39;s parts from Black Christmas, where it&#39;s almost like he&#39;s in an entirely different movie. Because he&#39;s always like on the phone and he&#39;s talking to somebody who&#39;s elsewhere in the movie. It&#39;s like they had him for a couple of days and so they just kind of did what they could.</p>
                            <p>Dave (05:50.85)
                            Yep.</p>
                            <p>Bryan White (05:53.035)
                            like basically like a couple of weeks in Rome for the two of them.</p>
                            <p>Dave (05:57.35)
                            Yeah, I mean, it&#39;s the same with The Visitor. It&#39;s like, why is John Houston in this movie? Other than what would John Houston do for beer money? Like, I think anything is the answer, but...</p>
                            <p>Bryan White (06:00.895)
                            Oh, I know, right?</p>
                            <p>Bryan White (06:08.395)
                            Oh yeah, I mean, this must have been a wicked quick paycheck. It was not, it demanded nothing of them at all. And I, and I love them both cause he, he, he&#39;s kind of wasted in city of the living dad. Like I hardly ever even remember that he&#39;s in the movie, but there&#39;s some, there are some really, really awesome jokes in this one that he just carries. We&#39;ve also got Paul Smith who plays, yeah, with the groundskeeper Willard. He played Bluto and Popeye and he was also the beast, Rabanne and Dune,</p>
                            <p>Dave (06:22.19)
                            Yeah, I forgot that he was in it.</p>
                            <p>Dave (06:31.65)
                            I&#39;ll see you in the next video.</p>
                            <p>Bryan White (06:38.435)
                            bunch of other movies like he.</p>
                            <p>Dave (06:39.55)
                            Yeah, nominated for an Oscar for Midnight Express like 30 years earlier.</p>
                            <p>Bryan White (06:45.055)
                            And here he is which I get they must have been like come on down You know you spend you know three weeks in Madrid and we&#39;ll pay you this much And he&#39;s like and all I gotta do is stroke this chainsaw sweet He&#39;s so menacing and so mean he&#39;s like the most aloof</p>
                            <p>Dave (06:54.274)
                            And then scowl.</p>
                            <p>Dave (06:58.81)
                            But also, like, confused a little bit too.</p>
                            <p>Bryan White (07:01.955)
                            Most of the time he does, they&#39;re all, man, all the shit. Well, fuck it, we&#39;ll get to it. But he has all these really weird interactions with everybody where like he stares them down with that like one-eye scowl for like 30 seconds too long before he answers their question. It&#39;s such a weird thing that he does, but he&#39;s great. So this movie began life originally as a made for TV movie called Jigsaw.</p>
                            <p>Dave (07:29.852)
                            It also, the other alternative title is, A Thousand Screams Has The Night.</p>
                            <p>Bryan White (07:34.155)
                            Yeah, yeah, it&#39;s called Milgritos tiene la noche, which means the thousands cruise. It sounds. It that is a Troy McClure comedy movie. Yeah, yeah, it&#39;s like that and Christmas Ape do do a double feature. So it this is this is a movie that like while I was watching it, it kind of struck me</p>
                            <p>Dave (07:37.75)
                            Which I tell you what, that is a romantic title.</p>
                            <p>Dave (07:43.59)
                            Yeah, I think that is that. Maybe it&#39;s just in the phrasing, but it&#39;s beautiful.</p>
                            <p>Bryan White (08:04.235)
                            who were operating at the time. And it&#39;s right around this time that like everybody in Europe starts to sort of jump on the train as well. Like it was like it was slash your fever in America. This was high time for that that whole thing like this was this was</p>
                            <p>Dave (08:16.97)
                            But this is weirdly, this is after this, like things in Spain were kind of dropping off in terms of exploitation. Cause this is like after Paul Nash eats after, what&#39;s his face, Jess Franco. So this comes out at a time like nobody&#39;s making anything in Spain as far as horror.</p>
                            <p>Bryan White (08:39.016)
                            I just, I heard like none of that. It was all choppy.</p>
                            <p>Dave (08:43.17)
                            That&#39;s fine, it&#39;s not that I&#39;m...</p>
                            <p>Bryan White (08:46.595)
                            But while I was watching it, it reminded me of just Franco&#39;s movie, Bloody Moon. That that one in particular, because it&#39;s it&#39;s like basically also both Spanish filmmakers, but they were. It&#39;s like they couldn&#39;t really figure out how to make it not Spanish. Like. The formula to make a slasher movie is stupid simple because everybody was doing it and most of them not bad.</p>
                            <p>Bryan White (09:16.655)
                            what these guys did, you know, if they were working in Spain, if they were working in France, if they were working in Italy, it always comes out like a Jaloh. And like maybe that&#39;s just because like, I mean, the slasher kind of has its roots in Jaloh to begin with, like Friday the 13th has some scenes that are just lifted directly from Bay of Blood. But they, they just cannot seem to separate their sensibilities. And they both like both</p>
                            <p>Dave (09:24.951)
                            Yeah.</p>
                            <p>Dave (09:34.47)
                            Yeah.</p>
                            <p>Bryan White (09:46.555)
                            nation. And this one, which I&#39;d say is a pretty good movie. But they both have like this really kind of awkward quality that it&#39;s like, if you removed some of the violence and just put somebody in front of it for like five minutes, I&#39;m pretty sure they&#39;d think you&#39;re trying to show a Maporno.</p>
                            <p>Dave (10:04.006)
                            Well, I was thinking like what if you were to describe this to someone without any knowledge, they would look at you and be like, why would I want to watch that? That sounds so boring. And also just terrible. And it is definitely terrible.</p>
                            <p>Bryan White (10:18.936)
                            Right, right, so.</p>
                            <p>Bryan White (10:22.695)
                            Yeah. Oh God. But anyways, let&#39;s, let&#39;s get into this cause this is, this is a riot. So, oh shit. No, because I definitely want to talk about that because the tagline is it&#39;s exactly what you think it is. And let me, let me say this though. It&#39;s not, I would like to know what they think that you think this movie is because there it is so deranged. It is.</p>
                            <p>Dave (10:29.406)
                            I mean, you skipped over the tagline for it, which...</p>
                            <p>Dave (10:38.347)
                            There you go.</p>
                            <p>Dave (10:48.146)
                            I&#39;ll tell you what, I don&#39;t think they give a shit what you think this movie is.</p>
                            <p>Bryan White (10:52.175)
                            I don&#39;t think they do either, but it&#39;s a very funny tagline. But when I was done watching this, I was like, I don&#39;t know what I was expecting. So that&#39;s the end.</p>
                            <p>Dave (11:02.586)
                            I believe I described it to you the other day as like someone asked a 14 year old kid to write a horror movie that takes place in an American university. Because this movie is just, it is a misunderstanding of everything.</p>
                            <p>Bryan White (11:11.36)
                            Ha ha ha</p>
                            <p>Bryan White (11:18.455)
                            cultural tone is so fucking funny. So this movie starts in like 1947, and it says, it specifically points at us as Boston, Massachusetts. And I&#39;m gonna say it right out front. I don&#39;t think anybody in this movie has ever been to Boston.</p>
                            <p>Dave (11:29.248)
                            Mm-hmm.</p>
                            <p>Dave (11:32.826)
                            Nope, nope, tell you what, I live there and it doesn&#39;t look anything like this.</p>
                            <p>Bryan White (11:37.416)
                            It&#39;s so idyllic. You know and we go we go into a kid&#39;s room we hear him doing the Humpty Dumpty thing and he&#39;s pretty</p>
                            <p>Dave (11:38.816)
                            Ah.</p>
                            <p>Dave (11:45.186)
                            Yeah, but isn&#39;t he a little too old to be like singing nursery rhymes to himself? Yeah, also he has an awful lot of pornography for a 12-year-old.</p>
                            <p>Bryan White (11:49.518)
                            This kid looks like he&#39;s like 12. And uh...</p>
                            <p>Bryan White (11:55.475)
                            Apparently, like his mom turns up a troubling amount of it. But yeah, so he&#39;s putting together a puzzle, you know, to sort of set the tone. And his mother comes in and it&#39;s like, oh, look at that. Look at little, look at my boy over there doing a puzzle. And then she comes over and finds out that he&#39;s putting together a poster, a puzzle of a nude woman. And she goes crazy.</p>
                            <p>Dave (11:59.506)
                            Now.</p>
                            <p>Dave (12:13.406)
                            Hmm.</p>
                            <p>Dave (12:18.966)
                            Yeah. Yeah, they fire you out of a cannon with this movie, where it&#39;s just like, here&#39;s the bitch true mother trope, everybody.</p>
                            <p>Bryan White (12:28.295)
                            this and this is this is really keeping with our sort of our trend of hysterical overacting because this this woman she reacts to it almost like like mommy dearest does in amityville too like her reaction is so humongous she freaks out moral panic she&#39;s like go get a bag i&#39;m gonna i&#39;m gonna burn all this stuff</p>
                            <p>Dave (12:35.406)
                            Yep.</p>
                            <p>Dave (12:51.566)
                            Yeah, it is a really heavy-handed reaction to, I mean, anything really, like...</p>
                            <p>Bryan White (12:56.415)
                            Yeah. And then, and then like for the next five minutes, she like angrily rifles through all of his stuff, just throwing shit around the room, finding apparently like magazine after magazine after magazine of porn. Yeah, like, I mean, he probably had some stag films, stuff, stuff somewhere. But he does not in fact go and get a bag. He instead gets an axe. And</p>
                            <p>Dave (13:11.007)
                            old timey pornography.</p>
                            <p>Dave (13:26.926)
                            It&#39;s hilarious.</p>
                            <p>Bryan White (13:28.676)
                            And oh, and he it&#39;s start it&#39;s this is the thing is he he hacks her to death and then she she goes to He yeah, so he apparently like stuffs her in a closet, but we don&#39;t see it happen Oh, he saws her into up to bits as well And there&#39;s like some woman trying to get into the house and she comes in with the cops</p>
                            <p>Dave (13:50.886)
                            with the cups with the most Spanish looking Boston cups I&#39;ve ever seen.</p>
                            <p>Bryan White (13:54.355)
                            The voice for both cops is the same guy. And so, yeah, they find him like crying in one of the, in one of the closets and you&#39;re like, big man, big man. And he&#39;s,</p>
                            <p>Dave (13:56.666)
                            Mm-hmm. Yep.</p>
                            <p>Dave (14:07.006)
                            Oh my god, but the best part about this whole scene is when one of the cops goes, we say something like, where&#39;s the father? And the woman pauses. This is the best line read I&#39;ve ever heard. The woman pauses and goes, he&#39;s away in Europe with the air force. Who the fuck is this?</p>
                            <p>Bryan White (14:21.143)
                            with the Air Force.</p>
                            <p>Bryan White (14:27.555)
                            Yeah, this she must have been somebody&#39;s friend and she&#39;s like, yeah, I&#39;m gonna really give it to him so that they call me back for another movie. Like she, yeah, she&#39;s</p>
                            <p>Dave (14:33.606)
                            Yeah. And that is a line rate in a movie that is full of great lines.</p>
                            <p>Bryan White (14:38.895)
                            She is, yeah, she has, she has forgotten what kind of movie that she&#39;s been making, but they find the kid is covered in blood. And, uh, uh, this also happens in blood rage, like right at the beginning where he, he, he kills the guy in the car and then he just kind of like smudges his brother with, with blood and sticks the weapon in his hand. Well, meanwhile, he is just like, basically he has been hit with a blood hose.</p>
                            <p>Dave (14:51.706)
                            Yep.</p>
                            <p>Bryan White (15:08.775)
                            to get away with X murder at the time. But also one of the things that I wanted to sort of point out take a minute to sort of recognize is the blood effects in this movie are really quite good. Usually when I don&#39;t know why I don&#39;t know what the disconnect is, but when you watch like European horror movies that have a good deal of gore in them, the blood always has a certain look to it. And I don&#39;t know if it&#39;s it&#39;s just the materials they&#39;re using to make it, or if it&#39;s</p>
                            <p>Bryan White (15:38.815)
                            but always kind of comes out looking like paint. So it&#39;s a little too thick or it&#39;s too thin and it looks like food coloring and syrup. But like what I found out about this one was when they went out to get all like the guts and stuff like that they also just took some blood with them. And so yeah. And so like a lot of the blood you see in this movie it&#39;s like real animal blood. So it has a particularly nasty look to it.</p>
                            <p>Dave (15:41.026)
                            Yeah, it&#39;s always orange paint.</p>
                            <p>Dave (15:57.026)
                            Yeah, the pounds and pounds of pig meat.</p>
                            <p>Bryan White (16:09.035)
                            really appreciate that. So this then we caught like this is this is sort of the break and we go to we go to credits and the type in the credits is awesome it&#39;s like this drippy blood font and it&#39;s there&#39;s really cool music playing over the over the credits and I thought the music in the movie was great but I also kind of felt like I recognized it from other places and that&#39;s</p>
                            <p>Dave (16:35.306)
                            And you sure did.</p>
                            <p>Bryan White (16:38.776)
                            like</p>
                            <p>Dave (16:39.666)
                            It&#39;s mostly the, it&#39;s Carlo Cordeo&#39;s score for absurd, most of it. And then there&#39;s a couple, I think, it&#39;s Silvio Cipriani, I think it&#39;s from a movie called Ring of Darkness, which is one of my favorite scores.</p>
                            <p>Bryan White (16:43.057)
                            Yeah, yeah.</p>
                            <p>Bryan White (16:51.741)
                            I recognized some Fabio Frizzzi as well. Like there were...</p>
                            <p>Dave (16:53.546)
                            There is a, there&#39;s like one or two Fabio Fritzi songs in there. It was originally done by a guy named Liberato Pestor. That&#39;s the guy who did the actual score for it.</p>
                            <p>Bryan White (16:56.315)
                            Yeah, whatever.</p>
                            <p>Bryan White (17:03.855)
                            So I guess maybe it&#39;s the Grindhouse version of the movie, but one of the sort of DVD-blue releases of this movie comes with the original Spanish soundtrack.</p>
                            <p>Dave (17:14.407)
                            Yeah, that&#39;s the grant husbands want to have.</p>
                            <p>Bryan White (17:16.615)
                            Okay, yeah, so so that one has the actual like original music and whatever No, shit</p>
                            <p>Dave (17:21.126)
                            And it doesn&#39;t work very well in it. You also, if you, I think if you watch that one, you have to watch it in Spanish because it&#39;s only exists in the Spanish cut of the movie.</p>
                            <p>Bryan White (17:28.918)
                            Cuz...</p>
                            <p>Bryan White (17:31.415)
                            Did they, so did they like shoot it twice? Like a version in Spanish and then a version?</p>
                            <p>Dave (17:37.146)
                            No, it&#39;s just overdubbing, the whole thing. Because the original audio track must sound like shit because the English version is also overdubbed.</p>
                            <p>Bryan White (17:38.755)
                            Wow. Cause a lot of...</p>
                            <p>Bryan White (17:46.175)
                            because the because the dub like the dub on the in the English version it matches the lips pretty well so I couldn&#39;t tell if they just I suppose that is but um So we come we come out of the we come out of the credits and man we get we get right to it so Oh, yeah, so uh groundskeeper is is</p>
                            <p>Dave (17:53.547)
                            And that depends on who&#39;s speaking, I think.</p>
                            <p>Dave (18:02.952)
                            And it was just, yeah. Ding, ding, ding, the Zany Comedy Alert.</p>
                            <p>Dave (18:10.691)
                            Oh no, you&#39;re gonna skip right over the skateboarding?</p>
                            <p>Bryan White (18:13.395)
                            Oh shit, no, because I do actually have a note. Let me, what the hell is that note? Oh yeah, listen, it&#39;s not that hard to write a skateboard. You can just step off. You don&#39;t have to fly through the two dudes carrying the mirror.</p>
                            <p>Dave (18:20.646)
                            No. No.</p>
                            <p>Dave (18:25.046)
                            Yeah, this is like 1950s. Yeah.</p>
                            <p>Bryan White (18:30.295)
                            This is, it&#39;s Buster Keaton shit. Cause like you see the girl on the, on the skateboard and she&#39;s like, whoa, whoa, whoa, like all over the place. And then you see the guys with the mirror and it&#39;s like, okay.</p>
                            <p>Dave (18:43.186)
                            Yeah, some real old timey comedy. No classic stuff.</p>
                            <p>Bryan White (18:47.536)
                            Oh yeah, yeah, but then we move in and there&#39;s the grounds</p>
                            <p>Dave (18:50.886)
                            And she goes through the sheet of glass. I can&#39;t believe you&#39;re skipping over. This is gold.</p>
                            <p>Bryan White (18:55.075)
                            Well, because you actually see her like step to stop and then they do the whole the whole shatter the whole shatter thing.</p>
                            <p>Dave (19:01.227)
                            And then yet she&#39;s fine five seconds later. That is her.</p>
                            <p>Bryan White (19:04.535)
                            Is that her? Because I got to tell you, this movie is so casually dismissive of the women in it that they are all like, there are a couple of parts where I&#39;m like, didn&#39;t she just get killed? Like there were there were moments where I was like, I don&#39;t know who this person is. Like the only characters that they really kind of drive home and sort of make you remember are like Christopher George, the it stinks guy, his stupid sidekick.</p>
                            <p>Dave (19:12.311)
                            No.</p>
                            <p>Bryan White (19:34.195)
                            Oh, that&#39;s about it.</p>
                            <p>Bryan White (19:37.495)
                            But yeah, so now we do the murder and it&#39;s a guy who&#39;s like head to toe, like cover it up. He&#39;s got the thing and there&#39;s a girl.</p>
                            <p>Dave (19:40.316)
                            Okay.</p>
                            <p>Dave (19:45.566)
                            Yeah, this is the grandest of guignol.</p>
                            <p>Bryan White (19:48.055)
                            Yeah, there&#39;s a girl reading out. She&#39;s got her like her skirt all hiked up because, you know, as we&#39;re going to find out like, Juan Simone is like the horniest director in the entire world. Yeah. And she&#39;s like, Hey, do you got to do that here? And he&#39;s like, Hey, don&#39;t worry, I&#39;ll be done in a minute. And then, you know, he fires it up and just casually like graces it across her neck. And there is a massive like wave of blood that comes from it.</p>
                            <p>Dave (20:00.067)
                            That 14 year old boy.</p>
                            <p>Dave (20:17.466)
                            Actually, before you go any further, because this movie is wall-to-wall misogyny, it is</p>
                            <p>Bryan White (20:21.075)
                            Oh my God, it&#39;s the worst. There&#39;s a part later on, like toward the end, that I have, like, that I really, really want to sort of like underscore as this part made me kind of uncomfortable, but we&#39;ll get there.</p>
                            <p>Dave (20:32.246)
                            I was thinking about it because this by any measure this movie is offensive. Like it doesn&#39;t matter who you are or where you&#39;re coming from. It is it is offensive. And yet it is so thoroughly charming. That you don&#39;t really give a shit how offensive it is. And I was like, why does this work? And I thought, you know what it&#39;s like, it&#39;s like when a five year old swears at you.</p>
                            <p>Bryan White (20:41.397)
                            Oh yeah.</p>
                            <p>Bryan White (20:53.475)
                            You&#39;re like, hey, yeah, it&#39;s like, oh, yeah, like, hey, yeah, like, oh, hey, don&#39;t don&#39;t be saying that, you know, just, you know, you can just do it here. But yeah, like there were parts like the Bruce Lacey and the Kung Fu professor part. That scene is really, really racist.</p>
                            <p>Dave (21:13.386)
                            Yeah. And just completely, like, it is a non sequitur with no explanation ever.</p>
                            <p>Bryan White (21:18.515)
                            It&#39;s totally weird and like where Bruce&#39;sploitation movies that big that you might get the reference like Because he doesn&#39;t look like Bruce Lee at all Anyways, but yeah, so and then now that now the investigation begins formally and this is where we meet Christopher George He&#39;s got his partner</p>
                            <p>Dave (21:28.546)
                            No, no.</p>
                            <p>Dave (21:39.466)
                            Oh wait, but is this before or after the scene in the hallway with the...</p>
                            <p>Bryan White (21:45.755)
                            Okay, so that&#39;s after this, because I&#39;ve got notes on that one too. Because, oh, no, I am not. I, we can spend some time on that one. But yeah, so I mean, and it&#39;s just, we meet the dean of the school, we meet Christopher George, his weirdo, like Frankenstein partner.</p>
                            <p>Dave (21:47.306)
                            Okay. Because you&#39;re not brazen by that line.</p>
                            <p>Dave (22:06.106)
                            Cool, I think his name is Randy. The most Spanish of names.</p>
                            <p>Bryan White (22:12.875)
                            And it&#39;s just like, oh, like, what do we know about the girl? Did she run around with a lot of guys? Like, was she a slut? Like, did she have this coming to her? You know, it&#39;s your average European police investigation of a murder. Like, this is basically every scene and every jello. Like, they just, I don&#39;t know. That&#39;s just their first thing. But then we get to the next sort of scene. Because this movie is kind of a hood on it,</p>
                            <p>Bryan White (22:42.595)
                            It&#39;s so fucking obvious who the killer is from the start, but they kind of stack it with a bunch of people who might be might be the killer. And so we&#39;ve got the dean to think of. We&#39;ve got the next guy who&#39;s professor Brown is his name, I think.</p>
                            <p>Dave (22:59.127)
                            Are you talking about the next scene is Chekhov&#39;s water bed?</p>
                            <p>Bryan White (23:02.155)
                            Yes, so it&#39;s like five or six kids in a hallway at their school, just smoking a joint in the hallway, and they are super horny.</p>
                            <p>Dave (23:12.056)
                            Yep.</p>
                            <p>Dave (23:16.286)
                            And these are the most European people you have ever seen in your life. They are all from French Vogue covers, like.</p>
                            <p>Bryan White (23:20.845)
                            All of the women have this.</p>
                            <p>Bryan White (23:25.675)
                            Yeah, yeah all the women in the movie have this kind of way of talking that I also kind of point out in my in my essay about Bloody moon where I don&#39;t know what it is or maybe it&#39;s just like somebody trying to keep up with the lip movement in the dub But they have this way of talking that&#39;s kind of like this and it&#39;s really kind of chirpy and stony and like they really It&#39;s kind of go go and go and go and it makes me want to fucking kill them</p>
                            <p>Dave (23:46.546)
                            Or they slow it down and they slow it down in the weirdest ways like say one of them says, hey, they&#39;ve just installed a waterbed in the training room and then they&#39;re smoking pot. Well, you know, I don&#39;t know why there is one, but what I do know and what I learned from this movie is the most beautiful thing in the world is smoking pot and fucking on a waterbed at the</p>
                            <p>Bryan White (23:57.484)
                            Why is there a waterbed in the training room?</p>
                            <p>Bryan White (24:10.615)
                            Like, yeah, yeah, yeah, hang on a second, because...</p>
                            <p>Dave (24:15.806)
                            Whoever wrote that line has never been on a waterbed.</p>
                            <p>Bryan White (24:19.515)
                            Yeah, because I&#39;m gonna say two stoners flailing awkwardly on an undulating surface. Probably not all that beautiful.</p>
                            <p>Dave (24:22.031)
                            Yeah.</p>
                            <p>Dave (24:24.806)
                            Like, even though you do not understand the biomechanics of sex because these things are not coming together.</p>
                            <p>Bryan White (24:30.175)
                            This was, this was like the high age of the water bed too. Like you don&#39;t see water beds anymore for a fucking reason. Super gross. Yeah. Like I would imagine that if I ever went into a place like that had a water bed, uh, yeah, there&#39;s probably like a kink for it. I would, this was just so, so weird. Like, so then we, we, we next, we move in where, uh, Professor Brown comes</p>
                            <p>Dave (24:36.786)
                            Yeah, cuz they&#39;re gross as fuck. They&#39;re incredibly tacky.</p>
                            <p>Dave (24:44.226)
                            I mean, no offense to the water bed enthusiasts out there, but, come on!</p>
                            <p>Dave (24:58.626)
                            My yes, the pink herring.</p>
                            <p>Bryan White (25:00.475)
                            Yeah, and she&#39;s, yes, he&#39;s poor, poor professor, poor professor Brown and his affliction. Yeah, but yeah, so one of the girls is like, oh, here he comes. Watch this and she goes over and is like, oh, can you tell me where my pectorals are? Everybody&#39;s making fun of me. And let me tell you, if I were this guy, I would have failed her on site. F minus, F minus for the semester. Get the fuck away from me.</p>
                            <p>Dave (25:04.007)
                            Eyo! Oh man.</p>
                            <p>Dave (25:23.826)
                            Yeah, I&#39;m like...</p>
                            <p>Dave (25:27.328)
                            Bitch, this is wildly inappropriate. This is a school.</p>
                            <p>Bryan White (25:31.575)
                            Yeah, but he&#39;s he&#39;s he&#39;s set up as kind of like a you know, he&#39;s one of the like, oh, maybe it&#39;s this guy and you know, the way he sort of behaves is very strange and he&#39;s very awkward and most of his scenes but right off the bat they they kind of.</p>
                            <p>Dave (25:44.546)
                            I mean, they dress him like Paul Lind from the get. Like, he has got on 10 pounds of pancake makeup. He may as well have an ass god.</p>
                            <p>Bryan White (25:47.818)
                            Oh yeah.</p>
                            <p>Bryan White (25:50.295)
                            No scene, no scene without a turtleneck. It&#39;s he&#39;s a riot and the thing is that we&#39;re supposed to sort of accept him as one of the one of the potential killers. But moments before the dean is like, ah, he&#39;s a slightly odd chap lives with his mother. It&#39;s like, well, his mother is dead. So I guess this, you know, knocks him off the list.</p>
                            <p>Dave (26:11.246)
                            Also, I mean, if they&#39;re trying to trick us, right, as the viewer, we know that whoever the killer is is the kid from the beginning, and that&#39;s like a good 40-something years earlier, which would make him in his like mid-50s at least.</p>
                            <p>Bryan White (26:21.799)
                            Right.</p>
                            <p>Bryan White (26:25.295)
                            This is why it&#39;s so fucking obvious, like right out of the gate who the killer is. So, um.</p>
                            <p>Dave (26:29.866)
                            Yeah. It&#39;s that or Christopher George, and I know it&#39;s not Christopher George. He would never.</p>
                            <p>Bryan White (26:35.315)
                            Right, right. Oh, but he every time he is in the shot, every scene he has, he has this running gag with a cigar that he just cannot get lit. He never has, he never has like matches, the people around him, nobody ever has matches. Somebody will be like, I don&#39;t smoke. This joke is legitimately funny. Like once about, about three quarters through the movie, I realized that like he&#39;s been</p>
                            <p>Dave (27:04.726)
                            Yeah, it&#39;s also the only consistent thing in this movie. There is no continuity elsewhere, except for this bit. God bless you, Juan. Juan Simone. You really did it.</p>
                            <p>Bryan White (27:12.856)
                            Oh yeah, yeah, but um...</p>
                            <p>Bryan White (27:18.255)
                            Yeah, but now they&#39;re gonna introduce another guy. And this is Paul Smith as Willard, the groundskeeper. And we are introduced to him as he is lovingly maintaining his chainsaw. It&#39;s the same chainsaw from the scene before. So like this is,</p>
                            <p>Dave (27:35.426)
                            Also, there are an awful lot of chainsaws just laying around this university.</p>
                            <p>Bryan White (27:39.715)
                            Yeah, yeah. And they&#39;re not just like, you know, like, I...</p>
                            <p>Dave (27:42.706)
                            Like I have never been anywhere where there are, where there&#39;s one chainsaw just sitting around, let alone 12.</p>
                            <p>Bryan White (27:46.775)
                            Yeah. And it&#39;s a big, it&#39;s a big chainsaw too. Like the, like the bars real long, like a Texas chainsaw massacre one, but the body of it is humongous. It&#39;s like it&#39;s got some kind of like a, like a car engine inside of it. It&#39;s a, it&#39;s a really strange thing, but he has shown like lovingly like brushing the blade. And this is where he, this is, you know, they throw this into, to throw us off. But then he kind of like looks into the distance.</p>
                            <p>Bryan White (28:16.895)
                            two kids under a tree in the schoolyard fucking. And it&#39;s like, is everybody fucking in this movie? Like it is, it is, like this, they must have like a single strain of STIs going through everybody, because like this, there&#39;s, there are scenes where like a dude whose girlfriend just got killed</p>
                            <p>Dave (28:41.986)
                            Also, this is New England. People don&#39;t do that. People don&#39;t... They don&#39;t even look people in the eye here.</p>
                            <p>Bryan White (28:49.376)
                            Right, right, this is not very chase. This is very Europe.</p>
                            <p>Dave (28:51.746)
                            I&#39;m not gonna say hello let alone fuck you under a tree!</p>
                            <p>Bryan White (28:54.775)
                            It&#39;s a very European take on Boston because obviously, obviously, you know, they&#39;re just kind of free that way.</p>
                            <p>Dave (28:57.69)
                            Got it.</p>
                            <p>Dave (29:00.608)
                            Gave her the old European hello.</p>
                            <p>Bryan White (29:02.635)
                            Yeah. So now, yeah, so now we got to introduce more characters. We go to the library and it&#39;s the it stinks guy from Pod People with the Mystery Science Theater 3000 episode where in that movie he&#39;s like a musician and he&#39;s really hard on his on his backup singers. And as soon as I saw him, I was like, oh, yes, I hope that like this is I hope that this is Kendall. And so I&#39;m like,</p>
                            <p>Dave (29:26.507)
                            Does he candle?</p>
                            <p>Bryan White (29:32.755)
                            or something because I knew he was a Spanish actor but it turns out he&#39;s like the main character and every single time he showed up is all I could think of was it stinks.</p>
                            <p>Dave (29:41.046)
                            Now in this movie, Kendall is supposed to be the campus stud. They actually call him the campus stud. My note just says, not in that cardigan.</p>
                            <p>Bryan White (29:45.795)
                            He looks... He...</p>
                            <p>Bryan White (29:50.435)
                            No, he looks like slim good body. He&#39;s like the weirdest looking guy. And he does. Yes. But like there is a scene where a girl is like literally begging him to have sex with her. And he&#39;s like, no way, baby. I gotta go.</p>
                            <p>Dave (29:53.146)
                            Yeah. He looks like Horsak.</p>
                            <p>Dave (30:08.128)
                            I gotta go save this police lady.</p>
                            <p>Bryan White (30:10.075)
                            Oh man, but yeah, so he gets like a note passed to him by a girl who says, I want to do it underwater. Meet me at the pool. So yeah, so so we cut. She&#39;s going to the pool and she is introduced with the swankiest sexiest saxophone like solo.</p>
                            <p>Dave (30:18.966)
                            Again, 14 year old boy.</p>
                            <p>Dave (30:28.766)
                            Yeah, yeah. And also, please note, she is walking at the pool in cowboy boots.</p>
                            <p>Bryan White (30:36.575)
                            That&#39;s true. Yeah, yeah. So yeah, gratuitous nudity follows. She undresses. She&#39;s waiting for Kendall, but the killer sort of steals into the scene and kind of hides out in the back.</p>
                            <p>Dave (30:37.969)
                            Yeah.</p>
                            <p>Bryan White (30:54.395)
                            And how he manages to pull this off is kind of anybody&#39;s guess because he&#39;s not dressed subtly. He is wearing like a wide brimmed fedora and it&#39;s all black and a black overcoat. And maybe his face is kind of covered with like a mask or something.</p>
                            <p>Dave (31:09.786)
                            He is like a character from Dick Tracy. Like everything about this screams like oversized costume.</p>
                            <p>Bryan White (31:15.855)
                            Yeah, he&#39;s like the shadow or something. Like it&#39;s really, really wild.</p>
                            <p>Dave (31:18.706)
                            or that suit uh... uh... what&#39;s his name there uh... was a guy from Talking Heads David yeah and then the stop making sense movie it&#39;s like that but it&#39;s a little less boxy maybe a little more fashionable</p>
                            <p>Bryan White (31:23.395)
                            Oh yeah, David Byrne. It stopped making sense. Ha ha ha.</p>
                            <p>Bryan White (31:31.775)
                            Yeah. So yeah, so she strips down and he, uh, subdues her with a pool, the pool net.</p>
                            <p>Dave (31:39.586)
                            with a net, put the net right over the head, he just catches her on the head with the net and she out like a light. Yeah.</p>
                            <p>Bryan White (31:43.455)
                            Yeah, he&#39;s like, he&#39;s taking her to the funny farm or something like that. But yeah, so he pulls her over and he fires up the old chain saw and cuts her to pieces. So what we, I think maybe I skipped over this earlier, but like after he killed the first girl, he took her head. And so after this one, he also bags up her torso.</p>
                            <p>Dave (32:10.306)
                            Yeah, I mean, if anyone&#39;s trying to follow along in the plot as you go beat by beat, you can give up now, because it&#39;s not gonna make any sense either way.</p>
                            <p>Bryan White (32:16.975)
                            It&#39;s, yeah, it&#39;s, it&#39;s kind of thrown together. So apparently originally this movie was just like an outline or a script treatment. They were only like 30 pages. Yeah. Cause like it&#39;s like a lot of this was made up on the day. So, um, now again, more cops. Everybody shows up to the, to the crime scene.</p>
                            <p>Dave (32:24.486)
                            Well, I got news for you. It&#39;s still kind of is.</p>
                            <p>Dave (32:36.106)
                            Okay, but here&#39;s what you get. What you get here is you get the, you know, the paramedics in Massachusetts. You can tell because of the traditional cassock uniforms they&#39;re wearing. These people are coming in here. Look, this is the most anachronistic paramedics I&#39;ve ever seen. They&#39;re like, it&#39;s like 1892.</p>
                            <p>Bryan White (32:48.732)
                            Yeah</p>
                            <p>Bryan White (32:57.175)
                            They got like smelling salts and cocaine in their bag. I mean, they probably had the cocaine in the bag, but you know. So there is a bloody chainsaw next to the severed pieces of the body and officer George comes in and asks, could that have been done with a chainsaw? Pointing to the chainsaw that is right next to the corpse</p>
                            <p>Dave (32:58.746)
                            Yeah.</p>
                            <p>Dave (33:18.972)
                            What gave it away?</p>
                            <p>Bryan White (33:27.055)
                            blood.</p>
                            <p>Dave (33:30.167)
                            And the professor, the, is he history professor, whatever it is? The gay one. He&#39;s the gay professor. He, he&#39;s the one who goes, well, I&#39;m not a pathologist, but like, I&#39;m neither am I. And yet I&#39;m going to go ahead and say, yeah, that&#39;s how this happened.</p>
                            <p>Bryan White (33:33.735)
                            He&#39;s, oh, he&#39;s the gay professor, comparative anatomy.</p>
                            <p>Bryan White (33:43.311)
                            It&#39;s</p>
                            <p>Bryan White (33:45.795)
                            It&#39;s no wonder the killer gets away with this for so long. These cops are fucking morons. So uh, yeah.</p>
                            <p>Dave (33:50.491)
                            Yeah.</p>
                            <p>Dave (33:52.786)
                            Well, it&#39;s Christopher George. He can&#39;t solve a crime. He&#39;s too busy being obsessed with Kendall.</p>
                            <p>Bryan White (33:57.155)
                            This is true. Oh my God, he takes him under his wing like it&#39;s his son that he never had or something. The relationship between those two is crazy, especially at the end when he keeps trying to like charge into the room. And the cops have to keep like literally throwing him against the wall. We are introduced to it stinks guys sidekick who I can&#39;t remember his name, but he&#39;s basically Melvin from the Toxic Avenger.</p>
                            <p>Dave (33:59.448)
                            Damn.</p>
                            <p>Dave (34:19.706)
                            Is this after Bluto comes in or like again he comes into the pool area?</p>
                            <p>Bryan White (34:26.015)
                            Yeah, I think, right, because I think this is where they arrest him, formally.</p>
                            <p>Dave (34:29.426)
                            And he has that look on his face, like these look- It&#39;s like, hey, wait a minute. What&#39;s going on here?</p>
                            <p>Bryan White (34:33.335)
                            Like, right, because he&#39;s always got like, he&#39;s always got like one eye closed. And the other one is kind of squinted and like really kind of skeptical of everything that&#39;s going on, almost as if to say, I have no idea what&#39;s happening here. And then they take him, I think they take him away. And yeah, it seems like we&#39;ve, we&#39;ve case closed, right? Because, you know, obviously this is a chainsaw that he was seen with. So, you know, I guess it just goes to, it just goes to say.</p>
                            <p>Bryan White (35:03.254)
                            But um...</p>
                            <p>Bryan White (35:06.595)
                            the like, I&#39;m not even really sure what this scene is for with the sidekick because he&#39;s just this kind of like obnoxious kid who like, he goes to tell Kendall that he found a note in the Dean&#39;s office or something for him. And then, and then, but before he, or maybe he does this or afterwards, he just turns to some random girl and was like, Hey, what are you doing later? And she&#39;s like, not on your life, mister.</p>
                            <p>Dave (35:21.348)
                            Alright, yeah</p>
                            <p>Dave (35:31.45)
                            My plans got cancelled. He&#39;s a weird character because he&#39;s like, what&#39;s his purpose? Because he&#39;s in it again.</p>
                            <p>Bryan White (35:39.076)
                            He shows up for the jump scare in the middle and he&#39;s just stalking the campus by night like in a monster mask. But yeah, I don&#39;t really know like comic relief maybe. Like it&#39;s, yeah, I don&#39;t know. But now we move to the dance scene.</p>
                            <p>Dave (35:52.829)
                            Okay.</p>
                            <p>Dave (36:00.406)
                            Ja.</p>
                            <p>Dave (36:03.847)
                            Oh wait, you skipped over the introduction of Mary Riggs.</p>
                            <p>Bryan White (36:07.395)
                            Was that there? Oh, I couldn&#39;t remember. I thought that it was after this.</p>
                            <p>Dave (36:08.29)
                            Yeah.</p>
                            <p>Dave (36:11.626)
                            Mary Riggs, tennis champion, slash lady cop.</p>
                            <p>Bryan White (36:14.975)
                            Yeah, she&#39;s she&#39;s so she&#39;s a professional tennis player and a paper pusher cop. That&#39;s like, I mean, does the tennis not pay in like what&#39;s what&#39;s going on here?</p>
                            <p>Dave (36:25.986)
                            I think she&#39;s supposed to be a retired, like she&#39;s really there. She&#39;s looking for the hot action and tennis, professional tennis wasn&#39;t doing it anymore.</p>
                            <p>Bryan White (36:31.156)
                            Yeah, it&#39;s just...</p>
                            <p>Bryan White (36:35.078)
                            Is this job gonna be dangerous? Yes, I&#39;m afraid so. Thank God.</p>
                            <p>Dave (36:40.586)
                            she really sells it. And this is also where we meet Sylvia Costa. She is an interpret reporter from the Madrid Globe.</p>
                            <p>Bryan White (36:41.916)
                            Yeah.</p>
                            <p>Bryan White (36:46.455)
                            the the reporter. I mean, they at least got the paper name right. She&#39;s actually from the Boston Globe, but.</p>
                            <p>Dave (36:53.186)
                            Yeah. Sylvia Costa, or as I call her, knockoff diary on Nickelodeon.</p>
                            <p>Bryan White (36:58.235)
                            or dead weight, you know, because she does nothing for this movie. She does.</p>
                            <p>Dave (37:01.286)
                            Yes, she is introduced into this movie to be killed.</p>
                            <p>Bryan White (37:04.636)
                            Yep.</p>
                            <p>Bryan White (37:08.195)
                            So now, dancing, it&#39;s a room full of women and like in leotards and they&#39;re dancing. Now originally,</p>
                            <p>Dave (37:15.847)
                            It&#39;s supposed to be ballet, I think.</p>
                            <p>Bryan White (37:18.675)
                            It&#39;s really hard to say because later on it&#39;s they do it but the music that they use is nuts It&#39;s like this high energy like it tallow disco song</p>
                            <p>Dave (37:27.806)
                            Yeah, I am, again, I&#39;m not an expert, but this is definitely not ballet. It&#39;s kind of like jazzercise a little bit.</p>
                            <p>Bryan White (37:33.355)
                            No, no, but it really is. So the woman in the sort of who&#39;s leading it with the like the black leotard and like the leg warmers. So apparently she was like a really a like an aerobics instructor. And yeah, and Simone wanted, he intended surprise, surprise for this scene to be done in the nude.</p>
                            <p>Dave (37:49.167)
                            Well that makes sense.</p>
                            <p>Bryan White (37:59.255)
                            which is ridiculous. So she had to take him aside and explain to him that in spite of what you might think, we don&#39;t usually do this naked. And apparently he was like, well, this is, yeah. He was like, cause he pushed back on her and it was only after he like, like they basically, she was like, there&#39;s no way I&#39;m doing this nude. And so he was like, fine. So he sounds like a gigantic asshole.</p>
                            <p>Dave (38:11.106)
                            I&#39;m stuck of what you might think.</p>
                            <p>Bryan White (38:29.295)
                            But here&#39;s a weird thing. Have you ever seen the movie Blood Diner? Okay, so Blood Diner? No, that&#39;s Blood Feast. Blood Diner is the, yeah, so that&#39;s the one that Jackie Kong did that was supposed to be like a sequel to Blood Feast. It has a very similar plot to this movie where the blood is being killed.</p>
                            <p>Dave (38:33.526)
                            Yes. The Herschel Gordon Lewis movie?</p>
                            <p>Dave (38:38.106)
                            Oh, Blood Diner, yes, this ladies.</p>
                            <p>Dave (38:43.23)
                            Yes.</p>
                            <p>Dave (38:50.406)
                            Also, Jackie Kong is still obsessed with that movie. She&#39;s working on a comic book now. It&#39;s like all she does.</p>
                            <p>Bryan White (38:55.075)
                            Oh, she&#39;s the she&#39;s the best. Oh my God. But the plots of the the plots of these movies are very similar, where both are sort of in a thing where they&#39;re they&#39;re making a woman out of pieces. But yeah, oops. But no, you know, you&#39;ve had 40 years to, you know, become familiar with this movie. But</p>
                            <p>Dave (39:09.607)
                            Oh, a spoiler alert.</p>
                            <p>Dave (39:13.506)
                            Again, it doesn&#39;t matter. It doesn&#39;t matter what they&#39;re doing.</p>
                            <p>Bryan White (39:25.235)
                            a nude aerobic scene, which is like such a weird connection to the whole thing. But so Jackie got her way, but Juan Simone could not. But the rhythm of the dancing and this just random song, and they are so far apart that like it was aggravating me. Like I was like anxious watching it. But like I, it&#39;s just kind of like a setup for the next kill.</p>
                            <p>Dave (39:39.207)
                            Oh, no match!</p>
                            <p>Bryan White (39:55.316)
                            They add the song she takes, they turn to the leader and goes, hey, I&#39;ll bear it back. I have to go to the John.</p>
                            <p>Dave (40:03.718)
                            Yes. That&#39;s the best part.</p>
                            <p>Bryan White (40:05.281)
                            Which like, hey, hey, really great, great, great performance. I gotta go take a dump.</p>
                            <p>Dave (40:10.891)
                            Oh, God!</p>
                            <p>Bryan White (40:16.395)
                            But this, this is just no point. She, she won&#39;t.</p>
                            <p>Dave (40:17.446)
                            Now, now imagine her saying that nude.</p>
                            <p>Bryan White (40:27.557)
                            I would have, I could...</p>
                            <p>Dave (40:28.466)
                            Look, that lady was not the director, but she made the right call.</p>
                            <p>Bryan White (40:35.737)
                            I don&#39;t I don&#39;t know I could I think that that would have made the scene a thousand times funnier, but um Yeah, yeah, so it&#39;s just this is set up. We follow her down the hall as she&#39;s going to the john and uh, it&#39;s the whole like</p>
                            <p>Dave (40:42.022)
                            Woo!</p>
                            <p>Dave (40:48.766)
                            And what is the most giallo of giallo scenes? The scene in the hallway, she&#39;s like running.</p>
                            <p>Bryan White (40:54.055)
                            Yeah, yeah, dark hallway and...</p>
                            <p>Dave (40:57.906)
                            They got like five out of fifty pound on the keys.</p>
                            <p>Bryan White (41:03.019)
                            Yeah, and yeah, it&#39;s just a setup for a jump scare.</p>
                            <p>Bryan White (41:08.275)
                            which, so, you know, like moving forward.</p>
                            <p>Bryan White (41:15.155)
                            there so we finally sort of see what Mary Riggs is there to do. And my note just says thrilling tennis action. There is a really long scene of the</p>
                            <p>Dave (41:26.77)
                            And it is the most boring.</p>
                            <p>Bryan White (41:30.715)
                            Slowest to these these are supposed to be like Professionals she&#39;s supposed to be a professional her opponent is supposed to be like a college player They&#39;ve got this audience behind them This this is actually it&#39;s true one of the things I read is that they they they were like here&#39;s your tennis racket She&#39;s like I don&#39;t play tennis so they had to get like a person to come in and teach them how to do it So that it could look at least a little bit familiar to them</p>
                            <p>Dave (41:41.766)
                            These people have played tennis twice.</p>
                            <p>Bryan White (42:00.735)
                            like white knuckle intensity in terms of like how interested in this.</p>
                            <p>Dave (42:03.026)
                            Yeah, they&#39;re doing that thing with the head, watches the ball, go back and forth.</p>
                            <p>Bryan White (42:07.615)
                            And I swear to God, it&#39;s like they got one good shot of Linda Day doing like a lob and they got one good shot of the other actress doing a lob and they just reuse the two shots like over and over and over because they both go to the same in the same direction and then eventually like Mary ends up winning. But it&#39;s so so long like what? What a what a what a</p>
                            <p>Bryan White (42:39.012)
                            But</p>
                            <p>Dave (42:39.586)
                            My guess is that it wasn&#39;t long enough.</p>
                            <p>Bryan White (42:43.715)
                            Yeah, or like, we&#39;ll get we&#39;ll get to a little further on. There&#39;s a couple of other moments where I was like, I wonder if if the director was like, Alright, just give me a few of these and then we&#39;ll kind of pick the good one. And then he was just like, You know what, these are all great. They&#39;re all going in there.</p>
                            <p>Dave (42:56.232)
                            Yeah.</p>
                            <p>Dave (43:05.507)
                            Yeah, this is definitely not ballet.</p>
                            <p>Bryan White (43:14.338)
                            And then she, you know, packs up and heads out and she runs into the killer again, but this time she recognizes him.</p>
                            <p>Dave (43:22.906)
                            This is the laziest kill scene in any horror movie, where she&#39;s just like, oh, it&#39;s you. And it&#39;s like, because you put two people in an elevator, one of them has a giant chainsaw. Like you cannot maneuver this thing.</p>
                            <p>Bryan White (43:26.998)
                            It really is.</p>
                            <p>Bryan White (43:32.255)
                            Yeah, but not just behind his, he&#39;s got a chainsaw behind his back. Like, he just was like, he walked in, there&#39;s no way she didn&#39;t see that thing going into the elevator. And then, yeah, like he has to like, he produces it, starts it up and you know, he gives her the old chop chop.</p>
                            <p>Dave (43:40.348)
                            Yeah.</p>
                            <p>Dave (43:50.926)
                            Like, do you think anybody was like, hey, what if we just did this not in an elevator? And he had more room to move around. And of course, Juan Simón was like, eh, you don&#39;t know what you&#39;re talking about.</p>
                            <p>Bryan White (43:55.099)
                            Oh, I know.</p>
                            <p>Bryan White (43:57.721)
                            Yeah.</p>
                            <p>Bryan White (44:01.215)
                            No, no, I&#39;m the director here. Hey lady, get naked.</p>
                            <p>Dave (44:03.866)
                            Yeah.</p>
                            <p>Bryan White (44:06.215)
                            So, yeah, so she gets killed and I can&#39;t remember. I think, does he take her legs? I think so, and then...</p>
                            <p>Dave (44:11.746)
                            I think so. I know her arms because she doesn&#39;t die. At first they&#39;re like, she&#39;s still alive. It&#39;s like, no, she is not.</p>
                            <p>Bryan White (44:16.555)
                            Oh, that&#39;s right. Yeah, there&#39;s no way because also this is another scene where like where the kill the kill goes down and it&#39;s not just a little bit of blood like it is like a gout. It&#39;s like an evil dead amount of blood that that sort of Yeah. Yeah. Um, so how how is it that</p>
                            <p>Dave (44:31.706)
                            Yeah, it&#39;s like she exploded in the elevator.</p>
                            <p>Bryan White (44:46.295)
                            realize that this happened because like this is the scene where he&#39;s in bed with the girl who&#39;s like</p>
                            <p>Dave (44:51.126)
                            Not yet, because I think he&#39;s... Is that where this scene is? Because he doesn&#39;t know about this.</p>
                            <p>Bryan White (44:56.275)
                            Oh, no. Right. Cause there&#39;s actually, there&#39;s another, there&#39;s another scene where they bring in the cops and the cops are like, dude, I don&#39;t know what&#39;s going on here. You know.</p>
                            <p>Dave (45:02.366)
                            Because he has to tell the cop in this frantic moment where the guys like I got to get over there whatever cops do and he&#39;s like oh he&#39;s because he wants to make a phone call is like hey. Dial zero for an outside line and I was like what a weird thing to include. It&#39;s like what do you know about telephone calls in the United States all I know is that you have to dial zero for an outside line.</p>
                            <p>Bryan White (45:14.244)
                            Oh.</p>
                            <p>Bryan White (45:17.035)
                            Yeah, so yeah, oh man.</p>
                            <p>Bryan White (45:24.577)
                            Yeah, and if you&#39;re having an emergency, you&#39;re called 999.</p>
                            <p>Dave (45:28.446)
                            Yeah, and then you just bang on the phone.</p>
                            <p>Bryan White (45:31.835)
                            Yeah, you mash your hand on the number pad.</p>
                            <p>Dave (45:33.838)
                            Yeah.</p>
                            <p>Dave (45:36.931)
                            Don&#39;t paint your dialy wand.</p>
                            <p>Bryan White (45:37.515)
                            right this is but this is this is this is one of the first scenes where where the detective George sort of takes candle under his wing and I don&#39;t know why I mean obviously it&#39;s just for plot expediency but like there&#39;s really no reason for him to sort of like have this like very very patriotly relationship with</p>
                            <p>Dave (45:48.048)
                            Yeah.</p>
                            <p>Dave (45:57.966)
                            Yeah, it is a very paternalistic response to this young man that&#39;s like, he is a stranger, who is now a cop too, I guess? He&#39;s like a deputy?</p>
                            <p>Bryan White (46:02.575)
                            This, he&#39;s a random kid.</p>
                            <p>Bryan White (46:07.755)
                            He&#39;s like, honor, he&#39;s like honorary, honorary, like booster or something like he, he takes a minute. He&#39;s like, Hey, uh, uh, look, you got to really look out for Mary, like protector. It&#39;s like, she&#39;s a cop, dude.</p>
                            <p>Dave (46:19.266)
                            Yeah, she&#39;s an adult woman, and a cop, and a professional tennis player. She&#39;s all of those things. She does not need Kendall.</p>
                            <p>Bryan White (46:24.855)
                            Yeah. Oh, and right. And Detective George is talking to her also. And she&#39;s like, like, I don&#39;t know if I got time for this. And he&#39;s like, look, Mary, I&#39;m short-handed. Like, dude, you&#39;re a cop in Boston. We like the only the only police like the police off like the police force that the only bigger police force is New York. Like this, you got cops who could be helping you because like also whenever they show like in the the office or at the</p>
                            <p>Dave (46:39.846)
                            Yeah. Trust me, you&#39;re not shorthanded.</p>
                            <p>Bryan White (46:55.455)
                            It looks like a basement of a library first of all, but like it&#39;s just him and like his partner Like there are no there&#39;s no indication that there are any other cops involved in the city like he is the one guy watching out for the entire city but Yeah, so then the murder happens and we we have this sort of moment where he&#39;s like, you know</p>
                            <p>Dave (47:14.946)
                            Now we get to the sex part with the...</p>
                            <p>Bryan White (47:17.815)
                            Yeah, so he wakes up in bed and he&#39;s got a girl with him, and he gets up and hangs dong, mind you. Sur...</p>
                            <p>Dave (47:25.406)
                            Yeah, this is a rare instance of male nudity in our movie from the 80s that is handled not, it&#39;s not gratuitous.</p>
                            <p>Bryan White (47:29.935)
                            I was very surprised.</p>
                            <p>Bryan White (47:34.795)
                            It&#39;s not, but it&#39;s also longer than I thought it would be. The scene that is, but.</p>
                            <p>Dave (47:37.666)
                            Yeah, that&#39;s what it&#39;s like. If all the nudity in this movie is going to be gratuitous, you know, expletative nudity, why is this scene not? And if that&#39;s the case, why is it in it at all?</p>
                            <p>Bryan White (47:48.255)
                            Yeah, but like the girl is like, oh, don&#39;t leave me, baby. Please, baby, come on back and, you know, do. Yeah, like do because you do be so good. Like it&#39;s such a. Very thirsty woman here. Presumably, like they just like just had sex. So he throws on his clothes and he goes out and Mary is out there walking around. And we&#39;re supposed to.</p>
                            <p>Dave (47:52.366)
                            Yeah, this part made me uncomfortable.</p>
                            <p>Dave (47:57.006)
                            Yes, she is desperate.</p>
                            <p>Dave (48:11.486)
                            What creeping around I think is a better description. She&#39;s like, it is like a Scooby-Doo episode. She is like creeping down these alleyways, like touching the walls. It&#39;s like a Michael Jackson video. Like she&#39;s just touching everything and looking a little bit scared.</p>
                            <p>Bryan White (48:14.616)
                            Yeah, sh-</p>
                            <p>Bryan White (48:22.055)
                            Yeah.</p>
                            <p>Bryan White (48:25.695)
                            They&#39;re like, give us some of that mission impossible business. Um, but yeah, so she&#39;s, she&#39;s just kind of wandering around and she turns a corner and is attacked by a karate dude. And he, he, he comes out of nowhere. He just starts kicking and kicking and kicking. She falls to the ground and kicks him in the balls. And, and that&#39;s, uh, you know, Hey, oh, she really rung his bell.</p>
                            <p>Dave (48:27.947)
                            Yeah.</p>
                            <p>Dave (48:37.339)
                            Oh</p>
                            <p>Dave (48:46.766)
                            and he passes out.</p>
                            <p>Bryan White (48:55.915)
                            sort of starting up and it&#39;s like oh no the chainsaw guy is coming but no it&#39;s Kendall in his motorcycle um and he looks at the guy who&#39;s getting up off the ground and he goes oh hey it&#39;s just my kung fu professor</p>
                            <p>Dave (48:59.389)
                            No.</p>
                            <p>Dave (49:06.126)
                            My kung fu professor, the greatest moment ever. My kung fu professor. What the fuck school do you think this is?</p>
                            <p>Bryan White (49:09.355)
                            And</p>
                            <p>Bryan White (49:14.695)
                            Yeah, he goes, he has, he then has a couple of minutes, the Kung Fu professor. And really it&#39;s one of the Bruce&#39;s, it&#39;s Bruce Lee, I think, who was in like Bruce Lee fights back from the grave and like all of those movies that were made after Bruce Lee died, the whole Bruce exploitation thing. He&#39;s one of them. And it&#39;s just because I can&#39;t remember what the producers</p>
                            <p>Dave (49:37.648)
                            The guy who&#39;s a producer, Rick something, Randy something? I wrote it down. Don Piano. Dick Randall is his name.</p>
                            <p>Bryan White (49:41.775)
                            Dubs, I can&#39;t remember. Donald, yeah. Dickran, yeah. So he, uh, he also was making a bunch of like Bruceploitation movies and was just like, Hey, you&#39;re in town, like pop up in, in here. And like his,</p>
                            <p>Dave (49:56.906)
                            And you&#39;d think this guy would have like looked at... This was not in the script, I&#39;m guessing. But you&#39;d think he would have looked at it and be like, I don&#39;t know about this, man. This is pretty tasteless. Even for you.</p>
                            <p>Bryan White (50:07.639)
                            Oh, because right, because like when he when he does speak, it&#39;s like, oh, so solid, old lady, you know, like it</p>
                            <p>Dave (50:12.286)
                            Yeah, it is very like number one son. This is like Mickey Rooney and Breakfast Statinity is offensive.</p>
                            <p>Bryan White (50:18.015)
                            Yeah, it&#39;s so terrible. It&#39;s literally it&#39;s like I watch that I&#39;m like oh I don&#39;t like that that&#39;s that&#39;s not good but um oh and then he just yeah he he just trots off and Kendall walks</p>
                            <p>Dave (50:26.046)
                            Yeah, no, I... My kung fu professor, god damn.</p>
                            <p>Dave (50:32.686)
                            Oh no, he explains why. Why he feels this way. It is because of the bad chop suey. Jesus fucking Christ.</p>
                            <p>Bryan White (50:37.775)
                            Oh, yeah, yeah.</p>
                            <p>Bryan White (50:43.435)
                            Yeah, that&#39;s that&#39;s that 80s business. You know, anything goes, baby. I gotta be so stuck up. Yeah. So Kendall takes to like, uh, walking her home and they get up to her house. And this is a guy who presumably like had sex 10 minutes ago. He immediately puts the back on her. Yeah. He&#39;s like, Hey, aren&#39;t you gonna like make some coffee? She&#39;s like, go home, Kendall.</p>
                            <p>Dave (50:49.646)
                            Wow. Wow.</p>
                            <p>Dave (51:03.206)
                            Yeah.</p>
                            <p>Dave (51:09.106)
                            I just, dude, get the fuck outta here. Someone just tried to kill me. Your Kung Fu professor just attacked me. I&#39;m gonna have sex with you.</p>
                            <p>Bryan White (51:16.955)
                            What the fuck is wrong with everybody in this movie? It&#39;s like they&#39;ve all had a head injury. But like this, and then he&#39;s like, oh, you know, oh well, kicks the dirt and like turns around with his hands in his pockets. And his weirdo sidekick shows up with a monster mask on his head and he does this weird thing with his hand and he makes a strange sound and he&#39;s like, ah, yeah, that&#39;s what it is.</p>
                            <p>Dave (51:41.546)
                            is like, hey, Casanova! Woo! It&#39;s just like, this is a weird kind of long duck-dog moment here that I, I don&#39;t know why it&#39;s in there, but I love it.</p>
                            <p>Bryan White (51:53.215)
                            Yeah, he just shows up for the jump scare. Like he&#39;s in the movie one more time. Um, but like this is all he seems to exist to do is just to kind of like make you, make you laugh. But like their idea of breaking tension is just like whenever, cause there&#39;s no timing to it. It just happens. So now.</p>
                            <p>Dave (52:13.186)
                            It&#39;s a it kind of feels like someone was like shot a bunch of scenes and then just put them all together. It&#39;s kind of like how I imagine Rob Zombie makes a movie. You just take a bunch of shit throw it up in the air crumpled all back together and there you go that&#39;s your movie.</p>
                            <p>Bryan White (52:26.615)
                            Yeah, yeah, like it basically follows the sort of progression of a plot where it&#39;s like, this happens and then this happens and then this happens and then we get to the end. And there&#39;s a little bit of connective tissue between it all because that&#39;s really all that&#39;s happening in this movie. It&#39;s just, it&#39;s crazy. But now it&#39;s the next day and there&#39;s more goddamn tennis. I have a tie, I have a note that says, no please, not more tennis. So this, it&#39;s just a setup and</p>
                            <p>Dave (52:48.714)
                            Mmm.</p>
                            <p>Dave (52:56.346)
                            Well, they got to build up for what is easily one of the greatest scenes ever captured on film.</p>
                            <p>Bryan White (53:03.255)
                            It&#39;s a crazy, oh yeah, oh yeah. But before that happens.</p>
                            <p>Dave (53:05.346)
                            You die, I&#39;m hoping you have that line, but I&#39;ve got a little audio sample of that because... Oh, it&#39;s on YouTube, and if not, it&#39;s on my goddamn cell phone because that&#39;s how much I love this scene.</p>
                            <p>Bryan White (53:10.259)
                            Well, let me uh</p>
                            <p>Bryan White (53:15.675)
                            Ha ha ha ha ha ha ha ha</p>
                            <p>Dave (53:20.126)
                            Like, he could say, no one has ever seen this movie, except every drag queen in America knows this scene.</p>
                            <p>Bryan White (53:20.157)
                            Yeah, so.</p>
                            <p>Bryan White (53:28.755)
                            So, this is basically, this is the run up to the next kill. Oh, shit, no, I actually forgot. There was actually another kill before all of this happened. The waterbed kill scene.</p>
                            <p>Dave (53:43.991)
                            Oh yeah.</p>
                            <p>Bryan White (53:44.955)
                            And so she...</p>
                            <p>Dave (53:46.866)
                            That&#39;s like, weird, that&#39;s like Dario Argento knockoff moment. When it&#39;s clearly a fake knife because it keeps bending.</p>
                            <p>Bryan White (53:50.596)
                            Right kid.</p>
                            <p>Bryan White (53:54.095)
                            It bends when he stabs her. But yeah, but also it&#39;s the first time that he&#39;s not using the chainsaw. It&#39;s, it&#39;s, like, and also, yeah, well, I mean, he stabs the whole thing. I mean, we finally see like the ultimate problem with having a waterbed, but.</p>
                            <p>Dave (54:00.166)
                            Well, you can&#39;t change saw through a waterbed. I mean, you can, but...</p>
                            <p>Dave (54:10.506)
                            Yeah, I mean, when you introduce a water bed in the first act, by the third act, it&#39;s gonna go off. And it goes off.</p>
                            <p>Bryan White (54:15.915)
                            Check, check off, and now I gotta check off water bed. It, yeah, oh man, it&#39;s a nasty, nasty scene, but I was wondering like, is that the girl from the beginning with the nothing?</p>
                            <p>Dave (54:24.491)
                            Yeah.</p>
                            <p>Dave (54:27.666)
                            No, that is the reporter.</p>
                            <p>Bryan White (54:31.378)
                            Oh, okay. Right.</p>
                            <p>Dave (54:32.906)
                            who they introduce as though she&#39;s going to be an important part of this movie, and she&#39;s never in it again until she gets killed in Chekhov&#39;s water pit.</p>
                            <p>Bryan White (54:41.395)
                            And to just to show you just to illustrate how much of an impression she makes, I had no idea who this woman was like again, like this next victim. I have no idea if she&#39;s been in the movie before, if she&#39;s a character, if we&#39;re familiar with her, but.</p>
                            <p>Dave (54:47.106)
                            Nope.</p>
                            <p>Dave (54:55.526)
                            I mean, I feel this way about a lot of horror movies, though.</p>
                            <p>Bryan White (54:58.755)
                            Yeah, yeah, I suppose, I mean...</p>
                            <p>Dave (55:00.766)
                            I mean, now to be fair, I am a gay man of a certain age. White women all look the same to me and let&#39;s put a hat on one, like some glasses on another. I can&#39;t be bothered. And I feel like Juan Simone, he also can&#39;t be bothered ostensibly for other reasons, but</p>
                            <p>Bryan White (55:06.464)
                            Ha ha ha!</p>
                            <p>Bryan White (55:18.455)
                            It just, yeah, it really, but it really shows. Cause like I have no idea who anybody is in this movie except for it stinks guy, Linda and, and George, but yeah, and blue dough, but um,</p>
                            <p>Dave (55:24.946)
                            No. Yeah. And a blue dough.</p>
                            <p>Dave (55:31.026)
                            You can tell him because he&#39;s the one who is scowling from beginning to end, but also a little bit confused.</p>
                            <p>Bryan White (55:36.996)
                            Yeah, so, yeah, because in the next scene, he&#39;s one of those parts where he&#39;s asked a question and then he gives a long, weird, right, right, because at one point, I can&#39;t remember what scene it is, but it&#39;s another one where I think they kinda wanna show you, it&#39;s a big wide shot of basically everybody of consequence in the movie to sort of be like, remember, here&#39;s all of the possible killers</p>
                            <p>Dave (55:45.886)
                            Yeah, he&#39;s not in jail anymore. He&#39;s now he&#39;s free or something.</p>
                            <p>Dave (56:04.028)
                            Yeah.</p>
                            <p>Bryan White (56:04.755)
                            He just kind of like strolls into the scene and kind of awkwardly steps into his spot with his hands in his...</p>
                            <p>Dave (56:10.426)
                            because the music is playing, it&#39;s like that triumphant, successful tennis music. I&#39;ll tell you, that is definitely not Stelvio Cipriani. It&#39;s like this Suza march, and it is extremely loud.</p>
                            <p>Bryan White (56:17.537)
                            Oh, right. Yeah, it&#39;s like that, that, you know.</p>
                            <p>Bryan White (56:21.895)
                            No, that&#39;s like JP. Yeah, it&#39;s like JP Souza. But uh...</p>
                            <p>Bryan White (56:28.655)
                            extremely loud and it&#39;s just like a sample played over and over and over again. And so again, more gratuitous nudity. Apparently this girl was like, um, the daughter of somebody on the crew who, uh, they were like, Hey, we need somebody in the movie, maybe chair my daughter out. And he&#39;s like, and Simone, of course, was like, would she do nude scene? And he&#39;s just like, and he&#39;s like, it&#39;s, it&#39;s Spain. So of course she&#39;ll do a nude scene.</p>
                            <p>Dave (56:50.707)
                            Jesus Christ!</p>
                            <p>Bryan White (56:58.775)
                            Again, I read more about this and there was like a lot of back and forth where she&#39;s like, yeah, well like I probably wouldn&#39;t be naked then like I can I wear sweatpants and he&#39;s like, I guess.</p>
                            <p>Dave (57:09.088)
                            This guy&#39;s got real Roman Polanski vibes.</p>
                            <p>Bryan White (57:11.415)
                            Wicked bad, wicked bad. Like I wouldn&#39;t be surprised if we found out he was a sex criminal. But this is the scene where she gets chased through the locker room by the killer with the chainsaw. And at this point, it is the most phallic chainsaw in the history of all movies involving chainsaws. Like he is holding it at his crotch.</p>
                            <p>Dave (57:34.506)
                            Yeah, when Carol Clover wrote Men, Women, and Chainsaws, she basically is just writing about this movie. Because every scene, he has got it. And I&#39;m not gonna give Juan Simón too much credit here because I do not think a lot of thought or effort went into this, but every time he&#39;s got the chainsaw, it&#39;s positioned at his crotch. That might just be because it&#39;s a chainsaw and it&#39;s gigantic, but it&#39;s pretty on the nose.</p>
                            <p>Bryan White (57:39.619)
                            pretty much.</p>
                            <p>Bryan White (57:55.915)
                            Right, but also this next kill scene in particular is a little bit of a bummer because like he chases her into like a, I don&#39;t know, a bathroom stall or something like that. She closes the door and puts her fucking back to it. And then of course, you know, the chainsaw bursts through.</p>
                            <p>Dave (58:15.606)
                            She has never seen any Friday the 13th movies.</p>
                            <p>Bryan White (58:18.495)
                            Yeah, but this but this is the part where he&#39;s like sawing his way through the door, but he&#39;s doing it any very Suggestive like back and forth in and out motion, and I&#39;m like oh god come on man But the whole thing is is really really silly on on the whole but this one in particular is just super super gross and There&#39;s a close-up of her like pissing her pants too, which is like</p>
                            <p>Dave (58:25.906)
                            Yep.</p>
                            <p>Dave (58:44.626)
                            Which is real. It is a real moment and they left it in probably because they were like, fuck it, we don&#39;t have the money to reshoot anything. It&#39;s because he comes way too close to her with the chains off and they never took the chain off of it.</p>
                            <p>Bryan White (58:55.075)
                            Well at 85 minutes.</p>
                            <p>Bryan White (59:01.796)
                            Oh my god, I had no idea.</p>
                            <p>Dave (59:04.226)
                            So that is a real moment.</p>
                            <p>Bryan White (59:05.875)
                            Jesus Christ. But like that&#39;s I mean that&#39;s gotta be it like this scene this is a girl who is not an actress She did she&#39;d never acted before she did not know what to expect and this is what they put her up against like She has a real as</p>
                            <p>Dave (59:18.546)
                            I mean, I&#39;d piss my pants if that guy was coming at me with a chainsaw.</p>
                            <p>Bryan White (59:21.975)
                            sure. Yeah, yeah, like no shade. I mean, that&#39;s, that&#39;s probably what you do. But like, as a result of this movie, she never acted again. But she gets, she gets chopped side on. And again, like, huge like explosion of blood. And you actually like see it going into the skin. Apparently, they chainsawed like a pig or something like that to get the thing. So, yeah, so now Bluto shows up,</p>
                            <p>Dave (59:43.307)
                            Yes, this was Pig Parts.</p>
                            <p>Dave (59:50.246)
                            Yeah, he is so put out by them, and all they want is to shut this goddamn song off.</p>
                            <p>Bryan White (59:55.355)
                            He&#39;s like, listen, that ain&#39;t my job, lady. But after that like a long, awkward pause where she&#39;s like, so how do we turn the music off? And he just like stares her down for like 30 seconds before he&#39;s like, don&#39;t touch. Yeah.</p>
                            <p>Dave (01:00:05.726)
                            And then he goes to do it and she&#39;s like, wait, don&#39;t touch anything. And it&#39;s like, but you just asked him to turn it off.</p>
                            <p>Bryan White (01:00:13.216)
                            Yeah, and and so they turn the music off. She goes outside with Pluto and Kendall comes up looking sick because he just found the body and this yeah, this is this is what what this is her reaction to the to the newest kill.</p>
                            <p>Dave (01:00:20.147)
                            Oh, here it goes.</p>
                            <p>Dave (01:01:00.248)
                            I mean this moment.</p>
                            <p>Bryan White (01:01:03.818)
                            This scene, I swear to God, this was one of those moments where I&#39;m sure he was like, okay, so we&#39;re gonna do the bastard scene. Give us three different intensities, different levels, and we&#39;ll pick the one that works best. And then she did that, and he was like, fuck it, we&#39;re keeping them all.</p>
                            <p>Dave (01:01:19.006)
                            What I love is that she does the first one and then she does the second one And then she waits you could see it on her face that she&#39;s just like should I do it? Should I do a third one and then she just goes it goes in for it and it is Whoo, it is it is like just fade done away and then was that network what it is beautiful</p>
                            <p>Bryan White (01:01:27.741)
                            Let&#39;s give him a third one.</p>
                            <p>Bryan White (01:01:37.559)
                            Yeah, oh you set me up for that one too and I gotta tell you like there&#39;s no way to undersell it like it is</p>
                            <p>Dave (01:01:43.106)
                            I had thought you had already made it past that point by the time I ruined it, but...</p>
                            <p>Bryan White (01:01:47.095)
                            Oh, it&#39;s so, it&#39;s so delightful. Cause it&#39;s like the one moment, she&#39;s practically sleeping through the whole movie. Like her parts are, you know, she&#39;s kind of like arm candy and a little, like a little quiet throughout the whole thing. Not.</p>
                            <p>Dave (01:01:50.627)
                            It&#39;s a crowning achievement.</p>
                            <p>Dave (01:01:59.806)
                            She&#39;s not a good actor. She&#39;s never a good actor. I heard Jackie beat describe her once as a What do you say? It&#39;s like a broom in a pantsuit with a wig on it And I was like, you know what that is shady as fuck, but it&#39;s also pretty true. She just she contributes very little</p>
                            <p>Bryan White (01:02:17.375)
                            Yeah. Yeah, like, I mean, she was just kind of like the pretty girl the week on like fantasy Highlander loveboat or something, you know?</p>
                            <p>Dave (01:02:24.806)
                            Yeah, she&#39;s Charles Angel&#39;s loveboat, the whole thing. When like anybody could be an actor.</p>
                            <p>Bryan White (01:02:28.075)
                            Yeah, so yeah, and so like we heard a little bit of it right before I cut it, but like we were moving on and Detective George is on the phone with I think his partner and he&#39;s like, like you gotta find me something, you gotta get me something, I got nothing. So like we are like three quarters of</p>
                            <p>Dave (01:02:45.926)
                            And then what the cop says, well, we don&#39;t have any. Oh, no, this is what it&#39;s. Christopher George says, we don&#39;t have any more time. It takes them uppers or something.</p>
                            <p>Bryan White (01:02:52.955)
                            Yeah, because they they they don&#39;t have anything. We&#39;re like, we are moving into the third act. This movie is almost over and he is just going like, well, this guy&#39;s got the drop on us. I have no idea what&#39;s going on here.</p>
                            <p>Dave (01:02:54.826)
                            Well.</p>
                            <p>Dave (01:03:06.206)
                            Yeah, cause he&#39;s spent it all his time like a, you know, fostering Kendall&#39;s position on the force now.</p>
                            <p>Bryan White (01:03:12.155)
                            Right. Um, so we&#39;re, we&#39;re, like, what are they even in this movie for? Like, what&#39;s the fucking point of these guys?</p>
                            <p>Dave (01:03:17.666)
                            What is anyone in this movie for? You have been talking about this for 55 minutes, and I bet when you listen back to this, it is not gonna make a goddamn bit of sense.</p>
                            <p>Bryan White (01:03:28.055)
                            Probably not, probably not. I mean, I hope somebody is actually like, who&#39;s listening to this, has seen this movie. Cause otherwise people are gonna be like, this movie sounds like it was made at a mental hospital.</p>
                            <p>Dave (01:03:38.446)
                            Like this got made? Like, yeah, and people love it. I love it.</p>
                            <p>Bryan White (01:03:42.375)
                            Yeah, because like, that&#39;s the thing, like when we do these sort of like back and forth on a movie, like it often sounds like we&#39;re shitting on it, but I assure you, like I enjoy this movie tremendously.</p>
                            <p>Dave (01:03:51.706)
                            photo.</p>
                            <p>Dave (01:03:55.287)
                            I spent my money on this movie. I own it.</p>
                            <p>Bryan White (01:03:59.762)
                            I&#39;m a special edition too like those great</p>
                            <p>Dave (01:04:00.986)
                            Now I own multiple copies of this film.</p>
                            <p>Bryan White (01:04:03.815)
                            Yeah, so yeah, we&#39;re moving we&#39;re moving through and we&#39;re kind of getting into the endgame and I can&#39;t remember how she gets there, but she Linda ends up at the Dean&#39;s house and</p>
                            <p>Dave (01:04:15.327)
                            where we get the final amazing line of the film. He says he makes her some coffee, which you know of course he puts some drugs in the coffee because he&#39;s gonna drug her.</p>
                            <p>Bryan White (01:04:23.575)
                            Right. Oh, but also, also my note is we&#39;ve replaced Linda Day&#39;s regular coffee with Folgers crystals. Let&#39;s see if she, let&#39;s see if she noticed because he, he apologizes. He apologizes. It&#39;s instant coffee. I&#39;m so sorry. Like, well, like</p>
                            <p>Dave (01:04:28.886)
                            Yeah, she doesn&#39;t. She loves the coffee.</p>
                            <p>Dave (01:04:36.646)
                            And then she goes, ah, I love the cream. And he goes, but of course, this is New England. What? What the fuck does that have to do with anything?</p>
                            <p>Bryan White (01:04:49.955)
                            No!</p>
                            <p>Dave (01:04:52.247)
                            But of course it is! This is New England after all where we put cream and coffee.</p>
                            <p>Bryan White (01:04:58.595)
                            No. I mean, I mean, it may, I, geez, maybe I mean, what&#39;s coffee in Spain like?</p>
                            <p>Dave (01:05:04.606)
                            I think they ran out of script is what happened here. They&#39;re like, I don&#39;t know fudge it. We got it. We got a rabbit up kids</p>
                            <p>Bryan White (01:05:06.595)
                            I mean, maybe because I&#39;m at a spot also where I&#39;m starting to kind of like forget what&#39;s happening because I&#39;ve got notes that just say in quotes, I&#39;ll send you a case of lollipops.</p>
                            <p>Dave (01:05:20.73)
                            Which I at first I was like is that like a like a kojak reference? Cause kojak is things like a lollipop.</p>
                            <p>Bryan White (01:05:26.995)
                            I thought the same thing, right, because he always had it. This was probably right around the same time. No, I think Kojak was kind of just like out of the 70s.</p>
                            <p>Dave (01:05:34.246)
                            Context late 70s into the early 80s.</p>
                            <p>Bryan White (01:05:36.515)
                            So I mean, maybe, I mean, maybe that&#39;s the, maybe that&#39;s the thing. But like this is when the, this is when the Dean reveal to Linda that Professor Brown is a homosexual. He&#39;s like, but you know, as long as he stays on the straight and narrow, I don&#39;t really mind.</p>
                            <p>Dave (01:05:46.572)
                            Mm-hmm</p>
                            <p>Dave (01:05:52.066)
                            Well, he says what he says is I honestly, I think he has more of a problem with it or he it&#39;s more of a big deal for him than it is for me. It was such a weird way of handling this because he obviously horror movies are notoriously homophobic and racist and misogynistic. And this is such a weird way of handling this character in general because the movie makes no it takes no position on it at all. It&#39;s just kind of like, here&#39;s the thing.</p>
                            <p>Bryan White (01:06:06.376)
                            Yeah.</p>
                            <p>Dave (01:06:22.106)
                            it&#39;s a sort of like misdirect and then the the slutty teens at the beginning are using it to like I don&#39;t really know what they&#39;re using it for but like it&#39;s never like uh the the character is not homophobic it&#39;s just sort of like oh it&#39;s just a character</p>
                            <p>Bryan White (01:06:23.877)
                            Right.</p>
                            <p>Bryan White (01:06:29.855)
                            break.</p>
                            <p>Bryan White (01:06:36.877)
                            It&#39;s right and it comes in a little too late to be like a Like butcher Baker would have would have introduced it like really early on as a way to sort of be like hey He&#39;s he&#39;s pretty fucked up right like he could totally be a chainsaw killer. I mean he&#39;s gay, right?</p>
                            <p>Dave (01:06:51.706)
                            And they do sort of, they make allusions to it early on when he&#39;s just like, ah yes, he&#39;s unmarried and lives with his mother, which is like, ding ding ding. He&#39;s a bachelor.</p>
                            <p>Bryan White (01:06:58.595)
                            Yeah. Yeah. Yeah. But like, it just, it drops right before it&#39;s revealed that the dean is the killer. And if you didn&#39;t see this coming a mile away, like maybe see a doctor. But yeah, this is the part where he, he, he, he refers to Professor, Professor Brown&#39;s homosexuality as his affliction. It was like, Jesus, Jesus Christ. But they also, we go to the police station</p>
                            <p>Dave (01:07:10.391)
                            Yeah.</p>
                            <p>Dave (01:07:18.766)
                            Yep.</p>
                            <p>Bryan White (01:07:28.575)
                            and he&#39;s like trying to find a connection and he finally does where yeah and I can&#39;t remember what the connection is but it&#39;s something like he finds out that the Dean&#39;s name is like something else.</p>
                            <p>Dave (01:07:31.507)
                            Yeah, he&#39;s just on the force now.</p>
                            <p>Dave (01:07:39.126)
                            The other cop, Frankenstein cop calls Arlington, I guess. And then I think it&#39;s that Arlington. And they tell him that it&#39;s not the Dean&#39;s real name or something like that. And then he&#39;s like, oh my God, he drops his Wendy&#39;s shake. He&#39;s like, we gotta get the fuck out of here, go over there. Because Kendall&#39;s all the backup he has now. Because he&#39;s like, well, where&#39;s Linda De George?</p>
                            <p>Bryan White (01:07:44.855)
                            Thank you. Thank you.</p>
                            <p>Bryan White (01:07:54.528)
                            Right, and then they look it up and...</p>
                            <p>Bryan White (01:08:03.756)
                            Yeah, yeah, and he shu-</p>
                            <p>Dave (01:08:08.512)
                            reason.</p>
                            <p>Bryan White (01:08:09.155)
                            Yeah. Oh, and then, yeah, and then they all run over there. They get there. And this is the part where they finally like hook up up outside the Dean&#39;s door and it stinks guy keeps trying to like rush in and he tries like three or four times and every time one of the cops grabs him and violently throws him back against the wall. That&#39;s like, I have no idea why he even feels such a strong urge to get in there.</p>
                            <p>Dave (01:08:30.506)
                            But this is my question, it&#39;s like, wait a minute, you have been treating him like he is a deputy for this entire movie. Now he&#39;s trying to charge in to save Linda DeGeorge, and now he can&#39;t commit? Like why did you bring him then?</p>
                            <p>Bryan White (01:08:44.255)
                            Right. And the whole reason that the Dean needs to kill her is because he wants her feet, which is very, very funny, because apparently the feet on the lady who chopped the legs off of don&#39;t</p>
                            <p>Dave (01:08:55.326)
                            Or no, he says something, he says something like you&#39;re famous, so they&#39;ll come looking for you or something. It doesn&#39;t matter, and none of it matters.</p>
                            <p>Bryan White (01:09:01.595)
                            Uh, yeah, but like I, it&#39;s something I, I assumed that it was like the shoes didn&#39;t fit the feet of the lady that he, that he killed. Cause there&#39;s that part where, where it&#39;s, it shows him like trying to put the shoes on her feet and they keep falling off. But yeah, so they go in and she&#39;s been drugged and he is like lovingly, uh, like handling her feet, which I gotta know what she was thinking in the moment. Cause she&#39;s supposed to be drugged and like can&#39;t speak or anything like that. And I mean, he is really pawing her feet.</p>
                            <p>Dave (01:09:07.806)
                            hahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahahah</p>
                            <p>Dave (01:09:15.96)
                            Thanks.</p>
                            <p>Bryan White (01:09:31.676)
                            Like he&#39;s quit Tarantino or something.</p>
                            <p>Dave (01:09:33.706)
                            They&#39;ve all, like at this point, they all know what movie they&#39;re in.</p>
                            <p>Bryan White (01:09:39.296)
                            But yeah, they show up, they shoot him. There&#39;s a bit of a struggle with him and Kendall, who gets into trouble and then Detective George comes in, puts a bullet in the dean, and then we start to kind of break down the...</p>
                            <p>Dave (01:09:52.126)
                            Which is weird for a movie that is not short on Buckets of Blood. The head shot to the Dean is just like someone flicked him with a little bit of something.</p>
                            <p>Bryan White (01:10:00.875)
                            Yeah, it&#39;s a wimpy little kill. Like they couldn&#39;t have sprung for a squib or something like that or... Oh yeah, it would have been like scanners. But yeah, so they&#39;re breaking it down. Like a body is there taken out and is it Kendall? Who does it? He sort of like casually puts his hand up on the bookshelf. The bookshelf spins around and there&#39;s the sort of us. Yeah, like the, oh my God, it&#39;s like a Vincent Price movie.</p>
                            <p>Dave (01:10:05.706)
                            I mean, if it were a lady, she&#39;d be exploded all over the wall, I guess.</p>
                            <p>Dave (01:10:25.126)
                            Oh yeah, very elaborate.</p>
                            <p>Bryan White (01:10:31.68)
                            And there is a...</p>
                            <p>Dave (01:10:31.846)
                            He grabs the candle, opera, then the whole thing turns, and it&#39;s an old stone staircase.</p>
                            <p>Bryan White (01:10:37.115)
                            Yeah, no, but it&#39;s actually the assembled body parts to like make a whole new woman. She&#39;s all like,</p>
                            <p>Dave (01:10:43.286)
                            This makeup, this makeup is like some real like eighth grade production of Frankenstein makeup. It&#39;s like I did it.</p>
                            <p>Bryan White (01:10:49.757)
                            the zombie movie the zombie movie in nightmare city the makeup in nightmare city is way better and that&#39;s a crap ass zombie makeup setup but like oh yeah oh yeah but she she falls over and right before we cut out they&#39;re about to leave Kendall like forgot his glasses or something and he turns</p>
                            <p>Dave (01:11:00.126)
                            Killer soundtrack though, I&#39;ll tell you that much.</p>
                            <p>Bryan White (01:11:16.455)
                            of a sudden, like out of nowhere, grabs him by the dick and rips it off.</p>
                            <p>Dave (01:11:21.546)
                            Now, and then freeze frame movies over.</p>
                            <p>Bryan White (01:11:23.895)
                            Uh, yeah, freeze fame on his agonized face.</p>
                            <p>Dave (01:11:27.326)
                            Now, here&#39;s the thing. This is all because of Carrie, because of the jump scare at the end of Carrie. That&#39;s why we have these in horror movies, because of that scene. Yes. Because it was so successful in Carrie, where at the end, Sue Snow goes to the grave and she&#39;s standing there and then they&#39;re armors up. But in Carrie, it&#39;s a dream. In Friday one, when he jumps up out of the water,</p>
                            <p>Bryan White (01:11:35.176)
                            Oh yeah. Is that why? Oh no shit.</p>
                            <p>Bryan White (01:11:48.876)
                            Yeah.</p>
                            <p>Bryan White (01:11:53.539)
                            Yeah.</p>
                            <p>Dave (01:11:57.686)
                            it is a assembled body of lady parts comes to life for whatever reason to grab his dick and then that&#39;s the end of the movie.</p>
                            <p>Bryan White (01:12:01.099)
                            Yeah, it is a left field.</p>
                            <p>Bryan White (01:12:11.256)
                            I will say this, I like that of everybody who got killed in this movie, he got it the worst.</p>
                            <p>Dave (01:12:20.286)
                            I guess I... I don&#39;t know. I&#39;m...</p>
                            <p>Bryan White (01:12:25.059)
                            Because there is a real brazen hatred of women going on in this movie that is a little unpleasant at times, but they at least stopped to rip the Casanova Studs dick off at the end.</p>
                            <p>Dave (01:12:39.847)
                            I don&#39;t this movie makes no fucking sense and again, it&#39;s gonna sound like this is such a pile of horseshit As a horror movie and it is but it is fucking hilarious to watch It is so charming in its weird offensive way that you&#39;re like nobody should be watching this movie and yet everybody And we should be watching it together on prime time television</p>
                            <p>Bryan White (01:12:53.872)
                            It is.</p>
                            <p>Bryan White (01:13:02.7)
                            Oh, yeah, it&#39;s I I really like this is one of the things like if I ever get around to actually doing it, it&#39;s a thing I still want to do is like organizing like movie screenings. This is one that&#39;s definitely getting shown because it is.</p>
                            <p>Dave (01:13:16.606)
                            Yeah, this is a movie that you really should watch with other people, because it&#39;s so outrageous.</p>
                            <p>Bryan White (01:13:19.555)
                            It would be like, did you, did you go to see Evil Dead 2 at the braddle with me? Oh, cause we went, I went with a few people. I thought you were there, but we went and saw, we saw Evil Dead 2. It was either there or as Coolidge Corner, but we, we went and the place was fucking packed and that movie in a packed theater is awesome. Cause like everybody&#39;s just going crazy the whole time.</p>
                            <p>Dave (01:13:26.191)
                            No.</p>
                            <p>Dave (01:13:30.987)
                            Ah, maybe I was.</p>
                            <p>Dave (01:13:48.966)
                            Yeah, these movies were made to be seen in theaters. No, they obviously there was no video at the time. And like they didn&#39;t anticipate that you&#39;re supposed to see these with other people.</p>
                            <p>Bryan White (01:13:51.312)
                            Yeah.</p>
                            <p>Bryan White (01:13:57.195)
                            Yeah, so apparently when this movie was like going into production, whoever was in charge of the studio that was producing it was like, I don&#39;t know if I want to do this. Like, you know, like they could. And so they tested it in front of an audience with him and the place was going bananas like everybody&#39;s.</p>
                            <p>Dave (01:14:06.386)
                            And they were like free trip to Spain!</p>
                            <p>Dave (01:14:14.506)
                            Do you know where that audience was though? It was a 42nd Street Theater. Yeah. So you took it to a Grindhouse Theater to show the movie. Like, of course they&#39;re gonna love it. Half of them are homeless people who sleep there. Like, no.</p>
                            <p>Bryan White (01:14:17.416)
                            Was it in Boston?</p>
                            <p>Bryan White (01:14:20.456)
                            Oh, no shit. So, yeah, but...</p>
                            <p>Bryan White (01:14:28.818)
                            Yeah.</p>
                            <p>Bryan White (01:14:32.776)
                            It&#39;s, it&#39;s, uh, man, it&#39;s just, it&#39;s such a demented piece of work. There were times where I was like, is this a college or is this a high school or is this a mental asylum? Like it is really not clear where this takes place. Like is it supposed to be Boston University or college?</p>
                            <p>Dave (01:14:50.846)
                            There is a scene at one point, and it&#39;s the very beginning, it&#39;s when they take the glass out of the... So I think some of the exteriors were actually shot in Boston.</p>
                            <p>Bryan White (01:14:57.681)
                            They were used in a they were used. They&#39;re all taken from another one of Simone&#39;s earlier movies that I think was actually shot Exteriors not in Boston, but like somewhere in America</p>
                            <p>Dave (01:15:06.906)
                            because when they take the glass out of the truck on the side of the truck, it says whatever phone number and then it says dead of mass. And for those who do not know, dead of is a suburb, but I don&#39;t know, 20 minutes south of the city.</p>
                            <p>Bryan White (01:15:13.195)
                            Oh, no shit, so, alright.</p>
                            <p>Bryan White (01:15:18.735)
                            Yeah, that&#39;s that&#39;s wild. So good God. Yeah, pieces are real, a real ride like</p>
                            <p>Dave (01:15:27.427)
                            I recommend this movie to everybody. It is incredibly fun. It sounds like it shouldn&#39;t be. It probably shouldn&#39;t be. It&#39;s deeply offensive.</p>
                            <p>Bryan White (01:15:30.11)
                            I&#39;m...</p>
                            <p>Bryan White (01:15:34.175)
                            Oh, it&#39;s so wrong in so many ways. But it&#39;s almost gleeful in how just against all notions of decency, everything about it is just run away. Yeah.</p>
                            <p>Dave (01:15:48.326)
                            This is a nihilistic movie. Like, they don&#39;t, they have no idea what&#39;s going on. They don&#39;t care. They don&#39;t care about continuity. They don&#39;t care whether you like it, what you think of it. It doesn&#39;t matter.</p>
                            <p>Bryan White (01:15:58.315)
                            even care if you notice that the knife folds when the guy stabs her in the head. Just the best.</p>
                            <p>Dave (01:16:02.086)
                            Nope. Nope.</p>
                            <p>Dave (01:16:06.186)
                            Like this movie is so, this is the epitome of an exploitation movie.</p>
                            <p>Bryan White (01:16:11.055)
                            Yeah, yeah, I mean, it makes perfect sense that like another one of this guy&#39;s movies ended up on Mystery Science Theater, because this very, very easily could have been a Mystery Science Theater movie if it weren&#39;t like so packed with violence and nudity. Because like it&#39;s already pretty short. Like it&#39;s not even 90 minutes, it&#39;s like 85 minutes. And if they were to trim like the really offensive stuff, this movie would be like 15 minutes long.</p>
                            <p>Dave (01:16:24.472)
                            Yeah.</p>
                            <p>Dave (01:16:36.606)
                            Yeah, I mean, there are, I can think of at least 10 other movies that I find legitimately offensive before I would name this one.</p>
                            <p>Bryan White (01:16:44.455)
                            Yeah.</p>
                            <p>Bryan White (01:16:47.495)
                            That&#39;s pieces. My God. So good. Pieces. So what are we doing next? I was going to say, right before we did this, I was talking about we got to get out in 1982 because we are stuck in the year and it&#39;s as great as it is. Maybe we can explore a little bit more.</p>
                            <p>Dave (01:16:49.474)
                            Ah, so good.</p>
                            <p>Dave (01:17:04.206)
                            Well, don&#39;t get too comfortable being out of 1982 because we&#39;re definitely coming back to it. But next time around, we are going to take a little trip to 1980 for Maniac.</p>
                            <p>Bryan White (01:17:15.155)
                            Yes, maniac. So in a, in a, in a sort of, we&#39;re flipping the script a little bit here. We&#39;re usually Dave&#39;s seen the movies and I&#39;m coming in fresh this time. This is a movie that I&#39;ve seen numerous times. I enjoy it tremendously, but he&#39;s not seen it. Nope. And I get to tell my Tom Savini story. So, uh, we&#39;ll see you back here in two weeks with maniac.</p>
                            <p>Dave (01:17:29.526)
                            Uh-uh.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/maniac">Next Episode</Link></li>
                        <li><Link to="/episodes/butcher-baker-nightmare-maker">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)